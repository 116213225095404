import { Link } from "react-router-dom";

export const SideWidget = () => {
  return (
    <aside className="col-lg-4 sidebar mt-8 mt-lg-6">
      <div className="widget">
        <h4 className="widget-title mb-3 font-bold">Books</h4>
        <p className="mb-2">
          ISA Surveillance Training LTD. company offers a series of covert
          surveillance books at various levels of complexity.
        </p>
        <ul className="image-list">
          <li>
            <figure className="rounded">
              <Link to="/isa-books/1">
                <img
                  className="border"
                  src="../assets/img/photos/book1.webp"
                  alt=""
                />
              </Link>
            </figure>
            <div className="post-content" style={{ margin: "auto 4rem" }}>
              <h6 className="mb-2">
                {" "}
                <Link className="link-dark" to="/isa-books/1">
                  The Geru Method Vol.1
                </Link>
              </h6>
            </div>
          </li>
          <li>
            <figure className="rounded">
              <Link to="/isa-books/2">
                <img
                  className="border"
                  src="../assets/img/photos/book2.webp"
                  alt=""
                />
              </Link>
            </figure>
            <div className="post-content" style={{ margin: "auto 4rem" }}>
              <h6 className="mb-2">
                {" "}
                <Link className="link-dark" to="/isa-books/2">
                  The Geru Method Vol.2
                </Link>
              </h6>
            </div>
          </li>
          <li>
            <figure className="rounded">
              <Link to="/isa-books/3">
                <img
                  className="border"
                  src="../assets/img/photos/bothBooks.webp"
                  alt=""
                />
              </Link>
            </figure>
            <div className="post-content">
              <h6 className="mb-2">
                {" "}
                <Link className="link-dark" to="/isa-books/3">
                  PROMO: The Geru Method Vol.1 + Vol.2
                </Link>
              </h6>
            </div>
          </li>
        </ul>
      </div>
      <div className="widget">
        <h4 className="widget-title mb-3 font-bold">Popular Courses</h4>
        <ul className="image-list">
          <li>
            <figure className="rounded">
              <Link to="/isa-surveillance-course-6">
                <img src="../assets/img/photos/a1.webp" alt="" />
              </Link>
            </figure>
            <div className="post-content">
              <h6 className="mb-2">
                {" "}
                <Link className="link-dark" to="/isa-surveillance-course-6">
                  DEEPENING COURSE IN COVERT SURVEILLANCE FOR PROFESSIONALS 
                </Link>
              </h6>
            </div>
          </li>
          <li>
            <figure className="rounded">
              {" "}
              <Link to="/isa-investigations-course-1">
                <img src="../assets/img/photos/a2.webp" alt="" />
              </Link>
            </figure>
            <div className="post-content">
              <h6 className="mb-2">
                {" "}
                <Link className="link-dark" to="/isa-investigations-course-1">
                  DEEPENING COURSE IN COVERT SURVEILLANCE MANAGEMENT - FOR MANAGERS, DIRECTORS, TEAM LEADERS
                </Link>{" "}
              </h6>
            </div>
          </li>
          <li>
            <figure className="rounded">
              <Link to="/isa-surveillance-course-8">
                <img src="../assets/img/photos/a3.webp" alt="" />
              </Link>
            </figure>
            <div className="post-content">
              <h6 className="mb-2">
                {" "}
                <Link className="link-dark" to="/isa-surveillance-course-8">
                  DEEPENING COURSE IN COVERT SURVEILLANCE - FOR THE SPECIALIZATION OF ORGANIZED CRIME, POLICE, CLOSE PROTECTION INTEGRATED AT GOVERNMENT LEVEL
                </Link>{" "}
              </h6>
            </div>
          </li>
        </ul>
      </div>

      <div className="widget">
        <h4 className="widget-title mb-3 font-bold">All Courses</h4>
        <ul className="unordered-list bullet-primary text-reset">
          <li>
            <Link to="/isa-surveillance-course-6">
              DEEPENING COURSE IN COVERT SURVEILLANCE FOR PROFESSIONALS 
            </Link>
          </li>
          <li>
            <Link to="/isa-investigations-course-1">
              DEEPENING COURSE IN COVERT SURVEILLANCE MANAGEMENT - FOR MANAGERS, DIRECTORS, TEAM LEADERS
            </Link>
          </li>
          <li>
            <Link to="/isa-surveillance-course-8">
              DEEPENING COURSE IN COVERT SURVEILLANCE - FOR THE SPECIALIZATION OF ORGANIZED CRIME, POLICE, CLOSE PROTECTION INTEGRATED AT GOVERNMENT LEVEL
            </Link>
          </li>
          <li>
            <Link to="/isa-investigations-course-3">
              DEEPENING COURSE IN COVERT SURVEILLANCE - FOR FOR STUDENTS WITH BACKGROUND IN THE MILITARY, POLICE AND OTHER RELATED SPECIALIZATIONS
            </Link>
          </li>
          <li>
            <Link to="/isa-intelligence-course-1">DEEPENING COURSE IN COVERT SURVEILLANCE - Organization of departments / structures / teams / covert surveillance, intelligence for companies, corporations, enforcement organizations with Link duration of 3 months

            </Link>
          </li>
          <li>
            <Link to="/isa-intelligence-course-2">
              DEEPENING COURSE IN COVERT SURVEILLANCE - Organization of departments / structures / teams / personal protection structured in plain sight and covert with complex mixed covert surveillance procedures and intelligence
            </Link>{" "}
          </li>
        </ul>
      </div>
    </aside>
  );
};
