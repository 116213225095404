import { Link } from "react-router-dom";
import { SideWidget } from "../SideWidget";

export const Disclaimer = () => {
  return (
    <>
      <section
        className="wrapper image-wrapper bg-image bg-overlay text-white"
        style={{ backgroundImage: 'url("../assets/img/photos/lglbg.webp")' }}
      >
        <div className="container pt-18 pb-15 pt-md-20 pb-md-19 text-center">
          <div className="row">
            <div className="col-md-10 col-xl-8 mx-auto">
              <div className="post-header">
                <div className="post-category text-line text-white">
                  <Link
                    to="/terms-and-conditions"
                    className="text-reset"
                    rel="category"
                  >
                    Legal
                  </Link>
                </div>
                <h1 className="display-1 mb-4 text-white">Disclaimer</h1>
                <ul className="post-meta text-white">
                  <li className="post-author">
                    <i className="uil uil-user" />
                    <Link to="#" className="text-reset">
                      <span>By ISA</span>
                    </Link>
                  </li>
                </ul>
                {/* /.post-meta */}
              </div>
              {/* /.post-header */}
            </div>
            {/* /column */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </section>
      {/* /section */}
      <section className="wrapper bg-light">
        <div className="container py-14 py-md-16">
          <div className="row gx-lg-8 gx-xl-12">
            <div className="col-lg-8">
              <div className="blog single">
                <div className="card">
                  <figure className="card-img-top">
                    <img src="../assets/img/photos/lglbg.webp" alt="" />
                  </figure>
                  <div className="card-body">
                    <div className="classic-view">
                      <article className="post">
                        <div className="post-content mb-5">
                          <h2 className="h1 mb-4">Disclaimer </h2>
                          <p
                            style={{
                              whiteSpace: "pre-line",
                              textIndent: " 50px",
                            }}
                          >
                            The information contained in this website is for
                            general information purposes only. The information
                            is provided by{" "}
                            <strong>ISA Surveillance Training LTD's</strong> and
                            while we endeavour to keep the information up to
                            date and correct, we make no representations or
                            warranties of any kind, express or implied, about
                            the completeness, accuracy, reliability, suitability
                            or availability with respect to the website or the
                            information, products, services, or related graphics
                            contained on the website for any purpose. Any
                            reliance you place on such information is therefore
                            strictly at your own risk.
                          </p>
                          <br />
                          <p
                            style={{
                              whiteSpace: "pre-line",
                              textIndent: " 50px",
                            }}
                          >
                            In no event will we be liable for any loss or damage
                            including without limitation, indirect or
                            consequential loss or damage, or any loss or damage
                            whatsoever arising from loss of data or profits
                            arising out of, or in connection with, the use of
                            this website.
                          </p>
                          <br />
                          <p
                            style={{
                              whiteSpace: "pre-line",
                              textIndent: " 50px",
                            }}
                          >
                            Through this website you are able to link to other
                            websites which are not under the control of{" "}
                            <strong>ISA Surveillance Training LTD's</strong>.We
                            have no control over the nature, content and
                            availability of those sites. The inclusion of any
                            links does not necessarily imply a recommendation or
                            endorse the views expressed within them.
                          </p>
                          <br />

                          <p
                            style={{
                              whiteSpace: "pre-line",
                              textIndent: " 50px",
                            }}
                          >
                            Every effort is made to keep the website up and
                            running smoothly. However,{" "}
                            <strong>ISA Surveillance Training LTD's</strong>{" "}
                            takes no responsibility for, and will not be liable
                            for, the website being temporarily unavailable due
                            to technical issues beyond our control.
                          </p>
                        </div>
                      </article>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <SideWidget />
          </div>
        </div>
      </section>
    </>
  );
};
