import { useEffect, useRef, useState } from "react";
import { Offcanvas } from "bootstrap";
import { Link } from "react-router-dom";
import { MiniBasket } from "../BasketComponent/MiniBasket";
import { useBasket } from "../Context/BasketContext";
import {useUser} from "../Context/UserContext";

export const HeaderHomepage = () => {
  const { basket } = useBasket();
  const [isIntersecting, setIsIntersecting] = useState(false);
  const { loggedIn,logOut } = useUser();
  const navbarRef = useRef(null);

  const navOffCanvasBtnRef = useRef(null);
  const navOffCanvasRef = useRef(null);
  let bsOffCanvas = null;
  useEffect(() => {
    const navOffCanvasBtn = document.querySelectorAll(".offcanvas-nav-btn");
    const navOffCanvas = document.querySelector(
      ".navbar:not(.navbar-clone) .offcanvas-nav",
    );
    bsOffCanvas = new Offcanvas(navOffCanvas, { scroll: true });
    const scrollLink = document.querySelectorAll(
      ".onepage .navbar li a.scroll",
    );
    const searchOffcanvas = document.getElementById("offcanvas-search");
    navOffCanvasBtn.forEach((e) => {
      e.addEventListener("click", (event) => {
        bsOffCanvas.show();
        const canvas = document.getElementById("offcanvas-container");
        if (canvas) {
          navOffCanvasRef.current.style.visibility = "visible";
        }
      });
    });
    scrollLink.forEach((e) => {
      e.addEventListener("click", (event) => {
        bsOffCanvas.hide();
      });
    });
    if (searchOffcanvas != null) {
      searchOffcanvas.addEventListener("shown.bs.offcanvas", function () {
        document.getElementById("search-form").focus();
      });
    }

    return () => {};
  }, [basket]);

  useEffect(() => {
    const handleScroll = () => {
      const rect = navbarRef.current.getBoundingClientRect();
      if (rect.y <= 2) {
        setIsIntersecting(true);
      } else {
        setIsIntersecting(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (isIntersecting) {
      navbarRef.current.classList.add("transparency_class");
    } else {
      navbarRef.current.classList.remove("transparency_class");
    }
  }, [isIntersecting]);

  return (
    <header className="wrapper sticky_header different_sticky ">
      <div className="bg-primary text-yellow fw-bold fs-15">
        <div className="px-4 py-2 d-md-flex submenu_container">
          <div className="d-flex flex-row align-items-center">
            <div className="icon text-yellow fs-22 me-2">
              {" "}
              <i className="uil uil-location-pin-alt" />
            </div>
            <address className="mb-0">
              146,R, Studio 210, London EC2A 3AR
            </address>
          </div>
          <div className="d-flex flex-row align-items-center me-6 ms-auto ">
            <div className="icon text-yellow fs-22 me-2">
              {" "}
              <i className="uil uil-phone-volume" />
            </div>
            <p className="mb-0">
              <Link to="tel:+40757047733">+40757047733</Link>
            </p>
          </div>
          <div className="d-flex flex-row align-items-center">
            <div className="icon text-yellow fs-22 me-2">
              {" "}
              <i className="uil uil-message" />
            </div>
            <p className="mb-0">
              <Link
                to="mailto:office@isasurveillancetraining.co.uk"
                className="link-yellow hover"
              >
                office@isasurveillancetraining.co.uk
              </Link>
            </p>
          </div>
        </div>
        {/* /.container */}
      </div>
      <nav
        ref={navbarRef}
        className="navbar navbar-expand-lg center-nav transparent navbar-light py-1"
      >
        <div className="container flex-lg-row flex-nowrap align-items-center">
          <div className="navbar-brand w-50">
            <Link to="/">
              <img
                style={{ width: "110px" }}
                src="./assets/img/logo.webp"
                srcSet="./assets/img/logo-2x.webp 2x"
                alt=""
              />
            </Link>
          </div>
          <div
            ref={navOffCanvasRef}
            id="offcanvas-container"
            className="navbar-collapse offcanvas offcanvas-nav offcanvas-start"
          >
            <div className="offcanvas-header d-lg-none">
              <h3 className="text-white fs-30 mb-0">
                ISA Surveillance Training LTD.
              </h3>
              <button
                type="button"
                className="btn-close btn-close-white"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              />
            </div>
            <div className="offcanvas-body ms-lg-auto d-flex flex-column h-100">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <Link className="nav-link" to="/">
                    Home
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/isa-about-us">
                    About Us
                  </Link>
                </li>
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle"
                    to="#"
                    data-bs-toggle="dropdown"
                  >
                    Training Courses
                  </Link>
                  <ul className="dropdown-menu">
                    <li className="nav-item">
                      <Link className="dropdown-item" to="/isa-all-training-courses">
                        All Training Courses
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="dropdown-item"
                        to="/isa-surveillance-courses"
                      >
                        Surveillance
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="dropdown-item"
                        to="/isa-investigations-courses"
                      >
                        Investigations
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="dropdown-item"
                        to="/isa-intelligence-courses"
                      >
                        Intelligence
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/isa-online-courses">
                    Online Courses
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/isa-books/all-books">
                    Books
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/isa-services">
                    Services
                  </Link>
                </li>
                {/*<li className="nav-item">*/}
                {/*  <Link className="nav-link" to="/isa-faq">*/}
                {/*    FAQ*/}
                {/*  </Link>*/}
                {/*</li>*/}
                <li className="nav-item">
                  <Link className="nav-link" to="/isa-blog">
                    Blog
                  </Link>
                </li>
              </ul>
              {/* /.navbar-nav */}
              <div className="offcanvas-footer d-lg-none">
                <div className="w-full break-all ">
                  <Link
                    to="mailto:office@isasurveillancetraining.co.uk"
                    className="link-inverse"
                  >
                    office@isasurveillancetraining.co.uk
                  </Link>
                  <br />
                  <Link to="tel:+40757047733">+40757047733</Link> <br />
                  <nav className="nav social social-white mt-4">
                    <Link to="#">
                      <i className="uil uil-twitter" />
                    </Link>
                    <Link to="#">
                      <i className="uil uil-facebook-f" />
                    </Link>
                    <Link to="#">
                      <i className="uil uil-dribbble" />
                    </Link>
                    <Link to="#">
                      <i className="uil uil-instagram" />
                    </Link>
                    <Link to="#">
                      <i className="uil uil-youtube" />
                    </Link>
                  </nav>
                  {/* /.social */}
                </div>
              </div>
              {/* /.offcanvas-footer */}
            </div>
            {/* /.offcanvas-body */}
          </div>
          {/* /.navbar-collapse */}
          <div className="navbar-other w-100 d-flex ms-auto">
            <ul className="navbar-nav flex-row align-items-center ms-auto">
              <li className="nav-item d-none d-md-block">
                <Link
                  to="/contact"
                  className="btn btn-sm btn-primary rounded-pill"
                >
                  Contact Us
                </Link>
              </li>
              <li className="nav-item dropdown">
                <Link
                    className="nav-link"
                    to="#"
                    data-bs-toggle="dropdown"
                >
                  <svg width="30" height="30" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"  fill="#343f52">
                    <path d="M12 10a3 3 0 100-6 3 3 0 000 6zm0 2a5 5 0 11.001-10.001A5 5 0 0112 12zm-7 8H3c0-4.418 4.468-8 9-8s9 3.582 9 8h-2c0-3.314-3.364-6-7-6s-7 2.686-7 6z"></path>
                  </svg>
                </Link>
                <ul className="dropdown-menu dropdown-menu-start right-dropdown">
                  {loggedIn ? <>
                    <li className="nav-item">
                      <Link
                          className="dropdown-item"
                          to="/isa-online-courses"
                      >
                        Online Courses
                      </Link>
                    </li>
                    <li className="nav-item" onClick={()=>logOut()}>
                      <Link className="dropdown-item" to="/">
                        Log out
                      </Link>
                    </li>
                  </>
                      :
                      <>
                        <li className="nav-item">
                    <Link className="dropdown-item" to="/login">
                      Login
                    </Link>
                  </li>
                    <li className="nav-item">
                      <Link
                          className="dropdown-item"
                          to="/register"
                      >
                        Register
                      </Link>
                    </li></> }
                </ul>
              </li>
              <li className="nav-item">
                <MiniBasket iconColor={"#343f52"} />
              </li>
              <li className="nav-item d-lg-none">
                <button
                  ref={navOffCanvasBtnRef}
                  className="hamburger offcanvas-nav-btn"
                >
                  <span />
                </button>
              </li>
            </ul>
            {/* /.navbar-nav */}
          </div>
          {/* /.navbar-other */}
        </div>
        {/* /.container */}
      </nav>
      {/* /.navbar */}
    </header>
  );
};
