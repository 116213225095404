import { Link } from "react-router-dom";
import { SideWidget } from "./SideWidget";
import Blog from "./BlogComponent/Blog";
import blogData from "./BlogComponent/blogData";

export const BlogComponent = () => {

  return (
    <>
      <section className="section-frame overflow-hidden">
        <div className="wrapper bg-soft-primary">
          <div className="container py-12 py-md-16 text-center">
            <div className="row">
              <div className="col-md-7 col-lg-6 col-xl-5 mx-auto">
                <h1 className="display-1 mb-3">ISA Courses - Blog</h1>
                <p className="lead px-lg-5 px-xxl-8 mb-1">
                  Here you can find the latest news.
                </p>
              </div>
              {/* /column */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container */}
        </div>
        {/* /.wrapper */}
      </section>
      {/* /section */}
      <section className="wrapper bg-light">
        <div className="container py-14 py-md-16">
          <div className="row gx-lg-8 gx-xl-12">
            <div className="col-lg-8">
              <div className="blog classic-view">
                <article className="post">
                  <div className="card">
                    <div className="card-body">
                      <div className="post-header">
                        {/* /.post-category */}

                      </div>
                      {/* /.post-header */}
                        <Blog posts ={blogData}/>
                      {/* /.post-content */}
                    </div>
                    {/*/.card-body */}
                  </div>
                  {/* /.card */}
                </article>
                {/* /.post */}
              </div>
              {/* /.blog */}
            </div>
            {/* /column */}
            <SideWidget />
            {/* /column .sidebar */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </section>
    </>
  );
};
