import { Link } from "react-router-dom";
import { SideWidget } from "../SideWidget";

export const SurveillanceCourse8 = () => {
  return (
    <>
      <section
        className="wrapper image-wrapper bg-image bg-overlay text-white"
        style={{ backgroundImage: 'url("../assets/img/photos/bg7.webp")' }}
      >
        <div className="container pt-18 pb-15 pt-md-20 pb-md-19 text-center">
          <div className="row">
            <div className="col-md-10 col-xl-8 mx-auto">
              <div className="post-header">
                <div className="post-category text-line text-white">
                  <Link
                    to="/isa-surveillance-courses"
                    className="text-reset"
                    rel="category"
                  >
                    Surveillance Courses
                  </Link>
                </div>
                {/* /.post-category */}
                <h1 className="display-1 mb-4 text-white">
                  DEEPENING COURSE IN COVERT SURVEILLANCE - FOR THE SPECIALIZATION OF ORGANIZED CRIME, POLICE, CLOSE PROTECTION INTEGRATED AT GOVERNMENT LEVEL
                </h1>
                <ul className="post-meta text-white">
                  <li className="post-author">
                    <i className="uil uil-user" />
                    <Link to="#" className="text-reset">
                      <span>By ISA</span>
                    </Link>
                  </li>
                </ul>
                {/* /.post-meta */}
              </div>
              {/* /.post-header */}
            </div>
            {/* /column */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </section>
      {/* /section */}
      <section className="wrapper bg-light">
        <div className="container py-14 py-md-16">
          <div className="row gx-lg-8 gx-xl-12">
            <div className="col-lg-8">
              <div className="blog single">
                <div className="card">
                  <figure className="card-img-top">
                    <img src="../assets/img/photos/b1.jpg" alt="" />
                  </figure>
                  <div className="card-body">
                    <div className="classic-view">
                      <article className="post">
                        <div className="post-content mb-5">
                          <h2 className="h1 mb-4">
                            DEEPENING COURSE IN COVERT SURVEILLANCE - FOR THE SPECIALIZATION OF ORGANIZED CRIME, POLICE, CLOSE PROTECTION INTEGRATED AT GOVERNMENT LEVEL
                          </h2>
                          <p
                            style={{
                              whiteSpace: "pre-line",
                              textIndent: " 50px",
                            }}
                          >
                            This type of courses is exclusive and will be
                            conducted only at the request of the government or
                            other governmental organizations, police schools and
                            will have special, complex subject matter applied to
                            the existing needs in this area, the subject matter
                            having a complex general part and a complex special
                            part by types of organized crime offences as the
                            actions are treated differently.
                          </p>
                          <p
                            style={{
                              whiteSpace: "pre-line",
                              textIndent: " 50px",
                            }}
                          >
                            The duration of such a course will be determined by
                            contract and is significantly longer than the other
                            courses mentioned above.
                          </p>
                          <p
                            style={{
                              whiteSpace: "pre-line",
                              textIndent: " 50px",
                            }}
                          >
                            Finally, I would like to inform you that ISA
                            SURVEILLANCE TRAINING LTD is strictly focused on
                            professional surveillance courses, from the basic to
                            the highest level, proactive surveillance, and
                            integrated close protection, wanting to do what we
                            do best. Who will leave us with the course done can
                            face any challenge with the request to continue
                            acting in professional surveillance with openness
                            only to the good things that can perform
                            surveillance.
                          </p>
                        </div>
                        {/* /.post-content */}
                      </article>
                      {/* /.post */}
                    </div>
                    {/* /.classic-view */}
                  </div>
                  {/* /.card-body */}
                </div>
                {/* /.card */}
              </div>
              {/* /.blog */}
            </div>
            {/* /column */}
            <SideWidget />
            {/* /column .sidebar */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </section>
    </>
  );
};
