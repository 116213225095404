import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { useBasket } from "../Context/BasketContext";
import course from "../../assets/img/course.webp";

export const Basket = ({ books, onlineCourses }) => {
  const { basket, setBasket } = useBasket();

  const [array, newArray] = useState(basket);

  const basketItems = array?.map((basketItem) => {
    if (basketItem.productId > 1000) {
      return {
        id: 1999,
        title: "Online course premium access",
        trailerVideoSrc: "",
        courseVideoSrc: "",
        content: "Unlock exclusive access to premium content! Invest in your learning journey and gain unlimited access to a wealth of knowledge.",
        link: "/isa-online-courses",
        price: 77.00,
        currency: "GBP",
        imgSrc: course,
        quantity: 1,
      };
    } else
      return {
        ...books[basketItem.productId - 1],
        quantity: basketItem.quantity,
      };
  });
  let totalSum = 0;

  basketItems.forEach((basketItem) => {
    totalSum = totalSum + basketItem.price * basketItem.quantity;
  });

  const deleteItem = (index) => {
    newArray([
      ...array.slice(0, index),
      ...array.slice(index + 1, array.length),
    ]);
  };

  useEffect(() => {
    setBasket(array);
    Cookies.set("basket", JSON.stringify(array));
  }, [array]);

  const increaseBasketItemQuantity = (index) => {
    const newItem = {
      productId: array[index].productId,
      quantity: array[index].quantity + 1,
    };
    newArray([
      ...array.slice(0, index),
      newItem,
      ...array.slice(index + 1, array.length),
    ]);
  };

  const decreaseBasketItemQuantity = (index) => {
    const newItem = {
      productId: array[index].productId,
      quantity: array[index].quantity - 1,
    };

    if (newItem.quantity === 0) {
      deleteItem(index);
      return;
    }
    newArray([
      ...array.slice(0, index),
      newItem,
      ...array.slice(index + 1, array.length),
    ]);
  };

  const CartItems = () => {
    return (
      basketItems &&
      basketItems?.map((product, index) => (
        <div
          className="justify-between mb-6 rounded-lg bg-white p-6 gap-4 shadow-md sm:flex sm:justify-start"
          key={index}
        >
          <img
            style={{ objectFit: "contain", borderRadius: "10px" }}
            src={product.imgSrc}
            alt={product.title}
            className="w-full rounded-lg sm:w-40"
          />
          <div className="sm:flex sm:w-full sm:justify-between gap-3">
            <div className="mt-0">
              <a
                className="text-lg font-bold text-gray-900 hover:underline"
                href={product.link}
              >
                {product.title}
              </a>
              <p
                className="mt-1 text-xs text-gray-700"
                style={{ whiteSpace: "pre-line" }}
                dangerouslySetInnerHTML={{
                  __html: `${product.content?.slice(0, 100)}...`,
                }}
              />
            </div>
            <div className="mt-4 flex justify-between sm:space-y-6 sm:mt-0 sm:block min-w-[120px]">
              {product.id < 1000 ? (
                <div className="flex items-center border-gray-100">
                  <span
                    className="cursor-pointer rounded-l bg-gray-100 py-1 px-3.5 duration-100 hover:bg-blue-500 hover:text-blue-50"
                    onClick={() => decreaseBasketItemQuantity(index)}
                  >
                    {" "}
                    -{" "}
                  </span>
                  <input
                    className="h-8 w-8 border bg-white text-center text-xs outline-none"
                    type="text"
                    value={product.quantity}
                    min="1"
                    readonly={true}
                  />
                  <span
                    className="cursor-pointer rounded-r bg-gray-100 py-1 px-3 duration-100 hover:bg-blue-500 hover:text-blue-50"
                    onClick={() => increaseBasketItemQuantity(index)}
                  >
                    {" "}
                    +{" "}
                  </span>
                </div>
              ) : (
                <div className="flex h-8 w-8 items-center justify-content-center border-gray-100"></div>
              )}
              <div className="flex items-center justify-content-between">
                <p className="text-sm">
                  {(product.price * product.quantity).toFixed(2)} GBP
                </p>
                <svg
                  onClick={() => deleteItem(index)}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="h-5 w-5 cursor-pointer duration-150 hover:text-red-500"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
      ))
    );
  };
  // let shippingCost =basketItems.some((item)=> {
  //   return item.id < 1000
  // }) ? 13: 0;
  let shippingCost = 0;
  return (
    <div className="h-full min-h-[550px] bg-gray-100 py-10">
      <h1 className="mb-10 text-center text-2xl font-bold">Cart Items</h1>
      <div className="mx-auto flex flex-col gap-6 justify-center px-6 max-w-5xl md:space-x-6 lg:flex-row xl:px-0">
        <div className="rounded-lg m-0 w-full lg:w-2/3  ">
          {totalSum === 0 ? (
            <h2
              style={{
                fontSize: "30px",
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                opacity: "70%",
                textAlign: "center",
              }}
            >
              Your cart is empty
            </h2>
          ) : (
            <CartItems />
          )}
        </div>
        {totalSum === 0 ? (
          <></>
        ) : (
          <div className="h-full  rounded-lg border m-0 bg-white p-6 md:space-x-0 shadow-md md:mt-0 w-full lg:ml-6 lg:w-1/3">
            <div>
              <div className="mb-2 flex justify-between">
                <p className="text-gray-700">Subtotal</p>
                <p className="text-gray-700">{totalSum.toFixed(2)} GBP</p>
              </div>
              <div className="flex justify-between">
                <p className="text-gray-700">Shipping</p>
                <p className="text-gray-700">
                  {totalSum > 0 ? shippingCost : 0} GBP
                </p>
              </div>
              <hr className="my-4" />
              <div className="flex justify-between">
                <p className="text-lg font-bold">Total</p>
                <div className="">
                  <p className="mb-1 text-lg font-bold">
                    {totalSum > 0 ? (totalSum + shippingCost).toFixed(2) : 0}{" "}
                    GBP
                  </p>
                  <p className="text-sm text-gray-700">including VAT</p>
                </div>
              </div>
              <Link to="../checkout">
                <button
                  className="mt-6 w-full rounded-md bg-[#001234] py-1.5 font-medium text-blue-50 hover:bg-blue-600"
                  disabled={basketItems.length <= 0}
                >
                  Check out
                </button>
              </Link>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
