import { Link } from "react-router-dom";
import mcLogo from '../../assets/img/MC.png';
import mcLogo2 from "../../assets/img/MC2.png";

export const FooterGeneral = () => {
  return (
    <footer className="bg-dark text-inverse ">
      <div className="container py-13 py-md-15">
        <div className="row gy-6 gy-lg-0">
          <div className="col-md-4 col-lg-3">
            <div className="widget">
              <img
                style={{ width: "70px" }}
                className="mb-4"
                src="./assets/img/logo-light.webp"
                srcSet="./assets/img/logo-light@2x.webp 2x"
                alt=""
              />
              <p className="mb-4">
                © 2022 ISA. <br className="d-none d-lg-block" />
                All rights reserved.
              </p>
              <nav className="nav widget ">
                <ul>
                  <li>
                    <Link to="/terms-and-conditions" className="text-xs">
                      Terms and Conditions
                    </Link>
                  </li>
                  <li>
                    <Link to="/disclaimer" className="text-xs">
                      Disclaimer
                    </Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy" className="text-xs">
                      Privacy Policy
                    </Link>
                  </li>
                  <li>
                    {" "}
                    <Link to="/copyright-policy" className="text-xs">
                      Copyright Policy
                    </Link>
                  </li>
                  <li>
                    {" "}
                    <Link to="/cookie-policy" className="text-xs">
                      Cookie Policy
                    </Link>
                  </li>
                </ul>
              </nav>
              {/* /.social */}
            </div>
            {/* /.widget */}
          </div>
          {/* /column */}
          <div className="col-md-4 col-lg-3">
            <div className="widget w-full break-all">
              <h4 className="widget-title text-white mb-3">Get in Touch</h4>
              <address className="pe-xl-15 pe-xxl-17">
                146,R, Studio 210, London EC2A 3AR, United Kingdom
              </address>
              <Link to="mailto:office@isasurveillancetraining.co.uk">office@isasurveillancetraining.co.uk</Link>
              <br /> +40757047733
              <br />
              <img
                  style={{ width: "200px" ,marginTop:'15px', borderRadius:'20px'}}
                  className="mb-4"
                  src={mcLogo}
                  alt="mc"
              />
            </div>
            {/* /.widget */}
          </div>
          {/* /column */}
          <div className="col-md-4 col-lg-3">
            <div className="widget">
              <h4 className="widget-title text-white mb-3 ">Learn More</h4>
              <ul className="list-unstyled  mb-0 ">
                <li>
                  <Link to="/isa-about-us">About Us</Link>
                </li>
                <li>
                  <Link to="/isa-all-training-courses">All Training Courses</Link>
                </li>
                <li>
                  <Link to="/isa-services">Services</Link>
                </li>
                {/*<li>*/}
                {/*  <Link to="/isa-faq">FAQ</Link>*/}
                {/*</li>*/}
                <li>
                  <Link to="/isa-blog">Blog</Link>
                </li>
              </ul>
              <img
                  style={{ width: "200px" ,marginTop:'15px', borderRadius:'20px'}}
                  className="mb-4"
                  src={mcLogo2}
                  alt="mc"
              />
            </div>
          </div>
          <div className="col-md-12 col-lg-3">
            <div className="widget">
              <h4 className="widget-title text-white mb-3">Our Newsletter</h4>
              <p className="mb-5">
                Subscribe to our newsletter to get our news &amp; deals
                delivered to you.
              </p>
              <div className="newsletter-wrapper">
                {/* Begin Mailchimp Signup Form */}
                <div id="mc_embed_signup2">
                  <form
                    action="https://elemisfreebies.us20.list-manage.com/subscribe/post?u=aa4947f70a475ce162057838d&id=b49ef47a9a"
                    method="post"
                    id="mc-embedded-subscribe-form2"
                    name="mc-embedded-subscribe-form"
                    className="validate dark-fields"
                    target="_blank"
                    noValidate=""
                  >
                    <div id="mc_embed_signup_scroll2">
                      <div className="mc-field-group input-group form-floating">
                        <input
                          type="email"
                          defaultValue=""
                          name="EMAIL"
                          className="required email form-control"
                          placeholder="Email Address"
                          id="mce-EMAIL2"
                        />
                        <label htmlFor="mce-EMAIL2">Email Address</label>
                        <input
                          type="submit"
                          defaultValue="Join"
                          name="subscribe"
                          id="mc-embedded-subscribe2"
                          className="btn btn-primary "
                        />
                      </div>
                      <div id="mce-responses2" className="clear">
                        <div
                          className="response"
                          id="mce-error-response2"
                          style={{ display: "none" }}
                        />
                        <div
                          className="response"
                          id="mce-success-response2"
                          style={{ display: "none" }}
                        />
                      </div>{" "}
                      {/* real people should not fill this in and expect good things - do not remove this or risk form bot signups*/}
                      <div
                        style={{ position: "absolute", left: "-5000px" }}
                        aria-hidden="true"
                      >
                        <input
                          type="text"
                          name="b_ddc180777a163e0f9f66ee014_4b1bcfa0bc"
                          tabIndex={-1}
                          defaultValue=""
                        />
                      </div>
                      <div className="clear" />
                    </div>
                  </form>
                </div>
                {/*End mc_embed_signup*/}
              </div>
              {/* /.newsletter-wrapper */}
            </div>
            {/* /.widget */}
          </div>
          {/* /column */}
        </div>
        {/*/.row */}
      </div>
      <div className="container pb-7 pb-md-7">
        <div className="row gy-6 gy-lg-0 align-middle justify-center">
          <Link
            to="https://redeclipse.ro/"
            target="_blank"
            style={{ width: "fit-content" }}
          >
            <img
              style={{ height: "70px", width: "auto", cursor: "pointer" }}
              src="../assets/img/photos/RedEclipseWhite.webp"
              alt="RedEclipse"
            />
          </Link>
        </div>
      </div>
    </footer>
  );
};
