const blogData = [
    {
        image: "https://logos-world.net/wp-content/uploads/2020/06/Linkedin-Logo-2003.png",
        title: "REAL MISTAKE AT TAKE IN SURVEILLANCE!",
        link: "https://www.linkedin.com/posts/colonel-r-doru-geru-93a59010a_real-mistake-at-take-in-surveillance-activity-7111420848640565248-m3_v/",
        content: ""
    },
    {
        image: "https://logos-world.net/wp-content/uploads/2020/06/Linkedin-Logo-2003.png",
        title: "REAL MISTAKE AT TAKE IN SURVEILLANCE!",
        link: "https://www.linkedin.com/posts/colonel-r-doru-geru-93a59010a_real-mistakes-in-covert-surveillance-as-promised-activity-7107713751440666624-6FAq/",
        content: "As promised, we analyze some WRONG METHODS of other provider who post on internet/youTube."
    },
    {
        image: "https://logos-world.net/wp-content/uploads/2020/06/Linkedin-Logo-2003.png",
        title: "INTERNATIONAL SURVEILLANCE ACADEMY",
        link: "https://www.linkedin.com/feed/update/urn:li:activity:7057782101999292416/",
        content: "the best change to become a real professional.\n" +
            "Online training:110 videos - 300 minutes./youTube."
    },
    {
        image: "https://logos-world.net/wp-content/uploads/2020/06/Linkedin-Logo-2003.png",
        title: "THE WORLD IS CHANGE\n" +
            "THE COVERT SURVEILLANCE ADAPTS TO THE NEW.",
        link: "https://www.linkedin.com/posts/colonel-r-doru-geru-93a59010a_the-world-is-change-the-covert-surveillance-activity-7110947619060785152-NJAH/",
        content: "It's time to learn at another level.\n" +
            "Notes:\n" +
            "New mistakes by some top providers are coming in next post.\n" +
            "It is important to learn correctly."
    },
    {
        image: "https://logos-world.net/wp-content/uploads/2020/06/Linkedin-Logo-2003.png",
        title: "BOOKS THE GERU METHOD- CONTENT",
        link: "https://www.linkedin.com/posts/colonel-r-doru-geru-93a59010a_books-the-geru-method-content-activity-7107006136641347585-YQVZ/",
        content: "When a trainer know this thinks, you can go to the course.\n" +
            "Meantime buy my books from\n" +
            "geruinvestigation.co.uk at chapter BOOKS reduced with 20% or from Amazon\n" +
            "Covert surveillance Volume 1 and 2./youTube."
    },
    {
        image: "https://logos-world.net/wp-content/uploads/2020/06/Linkedin-Logo-2003.png",
        title: "1. COVERT SURVEILLANCE BOOKS - BEST PRACTICES-THE GERU METHOD -VOLUME I\n" +
            "1. FOR COMPLEX AND DANGEROUS OPERATIONS - VOLUME II\n",
        link: "https://www.linkedin.com/posts/colonel-r-doru-geru-93a59010a_1-covert-surveillance-books-best-practices-the-activity-7103421864940625923-Z4bE/",
        content: "For those who want to develop their skills and obtain a qualification in this field, I recommend studying the 2 volumes at my course or alone.\n" +
            "These books are now available on the ISA SURVEILLANCE TRAINING LTD website:\n" +
            "geruinvestigation.co.uk\n" +
            "with big discounts for the entire package of the 2 Volumes/440 each of them, and for students who attend the ISA SURVEILLANCE TRAINING LTD courses.",
    },
    {
        image: "https://logos-world.net/wp-content/uploads/2020/06/Linkedin-Logo-2003.png",
        title: "RULES IN COVERT SURVEILLANCE",
        link: "https://www.linkedin.com/feed/update/urn:li:activity:7100114989436350465/",
        content: "According to the principles, the norms that must be respected in covert surveillance are the rules. They are not gold, platinum or silver. They are like mandatory conditions that we must take into account FOR EACH METHOD IN PART."
    },
    {
        image: "https://logos-world.net/wp-content/uploads/2020/06/Linkedin-Logo-2003.png",
        title: "COVERT SURVEILLANCE IS THE SAME WORLDWIDE ?",
        link: "https://www.linkedin.com/posts/colonel-r-doru-geru-93a59010a_covert-surveillance-is-the-same-worldwide-activity-7099037423380180992-aH44/",
        content: "A very disputed issue is the way to apply this science.\n" +
            "Covert surveillance provides\n" +
            "-principles,\n" +
            "-rules\n" +
            "-and diversified methods depending on the environment."
    },
    {
        image: "https://logos-world.net/wp-content/uploads/2020/06/Linkedin-Logo-2003.png",
        title: "Principles of Covert Surveillance",
        link: "https://www.linkedin.com/posts/colonel-r-doru-geru-93a59010a_principles-of-covert-surveillance-the-covert-activity-7097840185442279424-U3c_/",
        content: "The covert surveillance activity has mandatory principles and rules. Many people mix them up.\n" +
            "The difference between them is the following: The principles are the basis that must be fulfilled by the covert surveillance activity and the mandatory rules refer to the conditions for carrying out the covert surveillance methods."
    },    {
        image: "https://logos-world.net/wp-content/uploads/2020/06/Linkedin-Logo-2003.png",
        title: "Definition of Covert\n" +
            "Surveillance",
        link: "https://www.linkedin.com/posts/colonel-r-doru-geru-93a59010a_gerumethod-bestpractice-covertoperations-activity-7095680832312418304-j_-6/",
        content: "Covert Surveillance is a legal activity who obtain data, information and evidence from a target in a certain period of time.\n" +
            "Covert Surveillance must meet the following conditions\n" +
            "1. It is a legal method\n" +
            "2. It is a conspiracy method"},
    {
        image: "https://logos-world.net/wp-content/uploads/2020/06/Linkedin-Logo-2003.png",
        title: "As promised, the essence of my 30- year-work experience in covert surveillance",
        link: "https://www.linkedin.com/posts/colonel-r-doru-geru-93a59010a_as-promised-the-essence-of-my-30-year-work-activity-7093271295861096448-dZ0G/",
        content: "Organized crime is gathered in these two volumes",},
    {
        image: "https://logos-world.net/wp-content/uploads/2020/06/Linkedin-Logo-2003.png",
        title: "COVERT SURVEILLANCE WITH OR WITHOUT A GUN?",
        link: "https://www.linkedin.com/posts/colonel-r-doru-geru-93a59010a_covert-surveillance-with-or-without-a-gun-activity-7090245774155931648-1qI1/",
        content: "This is a discussion that has stirred spirits in different countries. There has always been the question of whether it is necessary for the operators to carry the gun.\n" +
            "In the judicial environment, the activities are complex, dangerous and unpredictable.",},
    {
        image: "https://logos-world.net/wp-content/uploads/2020/06/Linkedin-Logo-2003.png",
        title: "A life in the service of people. Seen or unseen.",
        link: "https://www.linkedin.com/posts/colonel-r-doru-geru-93a59010a_a-life-in-the-service-of-people-seen-or-activity-7079028747865120768-44tF/",
        content: "",
    },
    {
        image: "https://logos-world.net/wp-content/uploads/2020/06/Linkedin-Logo-2003.png",
        title: "A good surveillance officer must be a very good fire arms shooter as well.",
        link: "https://www.linkedin.com/posts/colonel-r-doru-geru-93a59010a_a-good-surveillance-officer-must-be-a-very-activity-7039662955633754117-ac-w/",
        content: "Two tips of shooting:\n" +
            "left continuous shooting\n" +
            "right fire with fire.\n" +
            "Shooting with a deadly weapon. Glock 19",
    },
    {
        image: "https://logos-world.net/wp-content/uploads/2020/06/Linkedin-Logo-2003.png",
        title: "Surveillance as an art!",
        link: "https://www.linkedin.com/posts/colonel-r-doru-geru-93a59010a_training-intelligence-surveillance-activity-7075446939273543680-Kotv/",
        content: "Surveillance was and is one of the elite intelligence specialties. It is the foundation of all covert actions and operations as well as security and close-protection specialisations."
    },
];


export default blogData;