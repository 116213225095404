import { Link } from "react-router-dom";
import { Post } from "../BooksComponents/Post";
import { SideWidget } from "../SideWidget";

export const Services = () => {
  return (
    <>
      <>
        <section className="section-frame overflow-hidden">
          <div className="wrapper bg-soft-primary">
            <div className="container py-12 py-md-16 text-center">
              <div className="row">
                <div className="col-md-7 col-lg-6 col-xl-5 mx-auto">
                  <h1 className="display-1 mb-3">ISA Services</h1>
                  <p className="lead px-lg-5 px-xxl-8 mb-1">
                    Here you can find our services.
                  </p>
                </div>
                {/* /column */}
              </div>
              {/* /.row */}
            </div>
            {/* /.container */}
          </div>
          {/* /.wrapper */}
        </section>
        {/* /section */}
        <section className="wrapper bg-light">
          <div className="container py-14 py-md-16">
            <div className="row gx-lg-8 gx-xl-12">
              <div className="col-lg-8">
                <div className="blog classic-view">
                  <article className="post">
                    <div className="card">
                      <div className="card-body">
                        <div className="post-header">
                          {/* /.post-category */}
                          <h2 className="post-title mt-1 mb-0">
                            ISA Intelligence Training Ltd. - Services{" "}
                          </h2>
                        </div>
                        {/* /.post-header */}
                        <div className="post-content">
                          <p>
                            Customers who want to provide integrated personal
                            protection activities with covert surveillance,
                            counterfilling, intelligence and close protection
                            methods can turn to us to carry out such services.
                            Please contact us by filling in the form on the
                            following link: Our teams will be coordinated by our
                            trainers in the three areas anywhere in the world
                            based on a written contract after paying the price
                            agreed by the parties. Our trainers are the
                            guarantee of the success of any action. We work as
                            we have done all our lives in special intelligence
                            services, surveillance and special actions.
                          </p>
                          <p>
                            We are waiting for you on the website of the company
                            ISA COVERT SURVEILLANCE Ltd for offering one or more
                            of our services.
                          </p>
                        </div>

                        {/* /.post-content */}
                      </div>
                      {/*/.card-body */}
                    </div>
                    {/* /.card */}
                  </article>
                  {/* /.post */}
                </div>

                {/* /.blog */}
                <div className="blog grid grid-view">
                  <div className="row isotope gx-md-8 gy-8 mb-8">
                    <Post
                      key="1"
                      link="/isa-services/investigations"
                      imgSrc="../assets/img/photos/investigations.webp"
                      title="Investigations"
                      content="
I have been conducting complex undercover investigations for organized crime for 22 years and, since 2012, with my personal company GD DIVISION SRL..."
                    />{" "}
                    <Post
                      key="2"
                      link="/isa-services/consultancy"
                      imgSrc="../assets/img/photos/consultancy.webp"
                      title="Consultancy"
                      content="Nowadays, the consultant understands that he is somewhere very high up in surveillance and gives advice, teaches lessons, delivers PowerPoint..."
                    />
                  </div>
                </div>
              </div>
              {/* /column */}
              <SideWidget />
              {/* /column .sidebar */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container */}
        </section>
      </>
    </>
  );
};
