import { Link } from "react-router-dom";
import { SideWidget } from "../SideWidget";
import VideoPlayer from "./VideoPlayer";
import { onlineCoursesData } from "./OnlineCoursesData";
import { useEffect } from "react";

export const OnlineCourses = () => {
  useEffect(() => {
    function handleContextMenu(e) {
      e.preventDefault();
    }
    const rootElement = document.getElementById("my-component");
    rootElement.addEventListener("contextmenu", handleContextMenu);

    return () => {
      rootElement.removeEventListener("contextmenu", handleContextMenu);
    };
  }, []);

  const coursesSection = onlineCoursesData.map((item, index) => (
    <article className="item post col-md-6" key={index}>
      <div className="card">
        <figure className="card-img-top overlay overlay-1 hover-scale">
          <img src={item.imgSrc} alt="course_thumbnail" />
        </figure>
        <div className="card-body">
          <div className="post-header">
            <h2 className="post-title h3 mt-1 mb-3">
              <Link className="link-dark" to={item.link}>
                {item.title}
              </Link>
            </h2>
            <div className="post-content">
              <p>{item.content}</p>
            </div>
          </div>
        </div>
      </div>
    </article>
  ));
  return (
    <>
      <>
        <div className="content-wrapper">
          <section className="section-frame overflow-hidden">
            <div className="wrapper bg-soft-primary">
              <div className="container py-12 py-md-16 text-center">
                <div className="row">
                  <div className="col-md-7 col-lg-6 col-xl-5 mx-auto">
                    <h1 className="display-1 mb-3">
                      INTERNATIONAL SURVEILLANCE ACADEMY
                    </h1>
                    <p className="lead px-lg-5 px-xxl-8 mb-1">
                      <strong>
                        Online Courses
                        <br />
                        Basic Covert Surveillance <br />
                        33 Videos <br />
                        Price: 77£ ( GBP ) <br />
                      </strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="wrapper bg-light">
            <div className="container py-14 py-md-16">
              <div className="row gx-lg-8 gx-xl-12">
                <div className="col-lg-8">
                  <div className="blog classic-view">
                    <article className="post">
                      <div className="card">
                        <div className="card-body">
                          <div className="post-header">
                            <h2 className="post-title mt-1 mb-0">
                              ISA Surveillance Training LTD. - COURSES{" "}
                            </h2>
                          </div>
                          <div className="post-content">
                            <header>
                              <h1>Welcome to ISA Surveillance Training Ltd.</h1>
                              <p>
                                We offer a comprehensive series of online covert
                                surveillance courses designed to equip you with
                                the essential skills and knowledge needed in the
                                field of surveillance. Our courses are
                                accredited by ISO 9001 and ISO 27001
                                certifications. You can access these courses
                                from anywhere, at your own pace.
                              </p>
                            </header>
                            <section>
                              <h2>Our Online Learning Environment</h2>
                              <p>
                                Our courses blend theory and practical training,
                                ensuring a well-rounded learning experience. We
                                understand the importance of gradual learning
                                and skill application. The courses commence with
                                theoretical training, followed by practical
                                exercises where you'll put your acquired
                                knowledge into action.
                              </p>
                            </section>
                            <section>
                              <h2>Key Highlights</h2>
                              <ul>
                                <li>
                                  <strong>Flexibility:</strong> You can access
                                  our courses from the comfort of your own home
                                  or office. No need to travel to a physical
                                  location.
                                </li>
                                <li>
                                  <strong>Comprehensive Training:</strong> ISA
                                  Covert Surveillance Training Ltd. provides all
                                  the necessary resources, including access to
                                  cars, communication tools, and hidden
                                  documentation methods. We also emphasize the
                                  crucial role of human preparation in
                                  surveillance activities. After all, the human
                                  factor is the most critical element in
                                  successful surveillance operations.
                                </li>
                                <li>
                                  <strong>Up-to-Date Techniques:</strong> Our
                                  training techniques are continuously updated
                                  and modernized to keep pace with the
                                  ever-changing landscape of covert
                                  surveillance.
                                </li>
                                <li>
                                  <strong>Balancing Act:</strong> We often hear
                                  the question, "What is more important:
                                  executing a surveillance operation
                                  comprehensively or relying on sophisticated
                                  equipment?" At ISA, we firmly believe that the
                                  person behind the equipment is the key. While
                                  technology is important, it's the skilled
                                  surveillance officer who drives the equipment
                                  and ensures the success of the operation.
                                </li>
                              </ul>
                            </section>
                            <section>
                              <h2>Our Online Courses</h2>
                              <ol>
                                <li>
                                  <strong>
                                    Introduction to Covert Surveillance:
                                  </strong>{" "}
                                  A foundational course covering the basics of
                                  covert surveillance techniques and principles.
                                </li>
                                <li>
                                  <strong>
                                    Intermediate Covert Surveillance:
                                  </strong>{" "}
                                  Dive deeper into the world of covert
                                  surveillance, exploring advanced methods and
                                  strategies.
                                </li>
                                <li>
                                  <strong>Advanced Covert Surveillance:</strong>{" "}
                                  Take your skills to the next level with
                                  advanced training in surveillance operations,
                                  risk assessment, and more.
                                </li>
                                <li>
                                  <strong>Specialized Surveillance:</strong>{" "}
                                  Learn about specialized areas of surveillance,
                                  such as cyber surveillance and corporate
                                  investigations.
                                </li>
                                <li>
                                  <strong>Ethics and Legal Framework:</strong>{" "}
                                  Understand the ethical considerations and
                                  legal regulations surrounding covert
                                  surveillance.
                                </li>
                                <li>
                                  <strong>
                                    Practical Exercises and Case Studies:
                                  </strong>{" "}
                                  Apply your knowledge in real-world scenarios
                                  through practical exercises and in-depth case
                                  studies.
                                </li>
                              </ol>
                            </section>
                            <footer>
                              <p>
                                Join us at ISA Surveillance Training Ltd. and
                                embark on a journey to master the art of covert
                                surveillance. Whether you're looking to enhance
                                your career or acquire new skills, our online
                                courses provide the flexibility and expertise
                                you need to succeed. Get started today!
                              </p>
                            </footer>
                          </div>
                        </div>
                      </div>
                    </article>
                  </div>
                  <div className="blog grid grid-view">
                    <div
                      className="row isotope gx-md-8 gy-8 mb-8"
                      id="my-component"
                    >
                      {onlineCoursesData.length > 0 ? (
                        coursesSection
                      ) : (
                        <h2
                          style={{
                            fontSize: "30px",
                            alignItems: "center",
                            justifyContent: "center",
                            display: "flex",
                            opacity: "70%",
                            textAlign: "center",
                          }}
                        >
                          Coming soon...
                        </h2>
                      )}
                    </div>
                  </div>
                </div>
                <SideWidget />
              </div>
            </div>
          </section>
        </div>
      </>
    </>
  );
};
