import { Link } from "react-router-dom";
import { SideWidget } from "../SideWidget";

export const TermsAndConditions = () => {
  return (
    <>
      <section
        className="wrapper image-wrapper bg-image bg-overlay text-white"
        style={{ backgroundImage: 'url("../assets/img/photos/lglbg.webp")' }}
      >
        <div className="container pt-18 pb-15 pt-md-20 pb-md-19 text-center">
          <div className="row">
            <div className="col-md-10 col-xl-8 mx-auto">
              <div className="post-header">
                <div className="post-category text-line text-white">
                  <Link
                    to="/terms-and-conditions"
                    className="text-reset"
                    rel="category"
                  >
                    Legal
                  </Link>
                </div>
                <h1 className="display-1 mb-4 text-white">
                  Terms and conditions
                </h1>
                <ul className="post-meta text-white">
                  <li className="post-author">
                    <i className="uil uil-user" />
                    <Link to="#" className="text-reset">
                      <span>By ISA</span>
                    </Link>
                  </li>
                </ul>
                {/* /.post-meta */}
              </div>
              {/* /.post-header */}
            </div>
            {/* /column */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </section>
      {/* /section */}
      <section className="wrapper bg-light">
        <div className="container py-14 py-md-16">
          <div className="row gx-lg-8 gx-xl-12">
            <div className="col-lg-8">
              <div className="blog single">
                <div className="card">
                  <figure className="card-img-top">
                    <img src="../assets/img/photos/lglbg.webp" alt="" />
                  </figure>
                  <div className="card-body">
                    <div className="classic-view">
                      <article className="post">
                        <div className="post-content mb-5">
                          <h2 className="h1 mb-4">Terms and conditions</h2>
                          <p
                            style={{
                              whiteSpace: "pre-line",
                              textIndent: " 50px",
                            }}
                          >
                            Welcome to our website. If you continue to browse
                            and use this website, you are agreeing to comply
                            with and be bound by the following terms and
                            conditions of use, which together with our privacy
                            policy govern{" "}
                            <strong>ISA Surveillance Training LTD's</strong>{" "}
                            relationship with you in relation to this website.
                            If you disagree with any part of these terms and
                            conditions, please do not use our website.
                          </p>
                          <br />
                          <p
                            style={{
                              whiteSpace: "pre-line",
                              textIndent: " 50px",
                            }}
                          >
                            The term{" "}
                            <strong>'ISA Surveillance Training LTD'</strong> or
                            'us' or 'we' refers to the owner of the website
                            whose registered office is 88 Church Road,
                            Stockton-On-Tees, TS18 1TW. Our company registration
                            number is 14242156, England. The term 'you' refers
                            to the user or viewer of our website.
                          </p>
                          <br />
                          <p
                            style={{
                              whiteSpace: "pre-line",
                              textIndent: " 50px",
                            }}
                          >
                            The use of this website is subject to the following
                            terms of use:
                          </p>{" "}
                          <br />
                          <ul style={{ textIndent: "50px" }}>
                            <li>
                              {" "}
                              • The content of the pages of this website is for
                              your general information and use only. It is
                              subject to change without notice.
                            </li>
                            <li>
                              • This website uses cookies to monitor browsing
                              preferences. If you do allow cookies to be used,
                              the following personal information may be stored
                              by us or by our trusted third-party payment
                              processor, PayPal, for the purpose of processing
                              payments:
                              <ul style={{ textIndent: "80px" }}>
                                <li>
                                  {" "}
                                  <strong> • Billing Information:</strong> This
                                  may include your name, billing address, and
                                  email address, as provided during the checkout
                                  process.
                                </li>
                                <li>
                                  {" "}
                                  <strong> • Payment Information:</strong> This
                                  may include your credit card or PayPal account
                                  details, which are securely processed by
                                  PayPal to complete the transaction.
                                </li>
                                <li>
                                  {" "}
                                  <strong> • Transaction Data:</strong>{" "}
                                  Information related to your purchases, such as
                                  order details and payment history.
                                </li>
                              </ul>
                            </li>
                            Please note that while we may collect certain
                            information to facilitate transactions, the handling
                            of this data by PayPal is subject to their own
                            privacy policy and terms of service. We recommend
                            reviewing PayPal's privacy policy for more
                            information on how they handle and protect your
                            data.
                            <li>
                              {" "}
                              • Neither we nor any third parties provide any
                              warranty or guarantee as to the accuracy,
                              timeliness, performance, completeness or
                              suitability of the information and materials found
                              or offered on this website for any particular
                              purpose. You acknowledge that such information and
                              materials may contain inaccuracies or errors and
                              we expressly exclude liability for any such
                              inaccuracies or errors to the fullest extent
                              permitted by law.
                            </li>
                            <li>
                              {" "}
                              • Your use of any information or materials on this
                              website is entirely at your own risk, for which we
                              shall not be liable. It shall be your own
                              responsibility to ensure that any products,
                              services or information available through this
                              website meet your specific requirements.
                            </li>
                            <li>
                              {" "}
                              • This website contains material which is owned by
                              or licensed to us. This material includes, but is
                              not limited to, the design, layout, look,
                              appearance and graphics. Reproduction is
                              prohibited other than in accordance with the
                              copyright notice, which forms part of these terms
                              and conditions.
                            </li>
                            <li>
                              {" "}
                              • All trademarks reproduced in this website which
                              are not the property of, or licensed to, the
                              operator is acknowledged on the website.
                            </li>
                            <li>
                              {" "}
                              • Unauthorized use of this website may give rise
                              to a claim for damages and/or be a criminal
                              offence.
                            </li>
                            <li>
                              {" "}
                              • From time to time this website may also include
                              links to other websites. These links are provided
                              for your convenience to provide further
                              information. They do not signify that we endorse
                              the website(s). We have no responsibility for the
                              content of the linked website(s).
                            </li>
                            <li>
                              {" "}
                              • Your use of this website and any dispute arising
                              out of such use of the website is subject to the
                              laws of England, Northern Ireland, Scotland and
                              Wales.
                            </li>
                          </ul>
                          <br />
                        </div>
                      </article>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <SideWidget />
          </div>
        </div>
      </section>
    </>
  );
};
